import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const headerStyle = {
    background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/images/header.jpg.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "200px",
    width: "100%",
    margin: 0,
    padding: 0,
  };

  return (
    <header className="header" style={headerStyle}>
      <div className="container">
        <div className="logo">
          <Link to="/">
            <img
              src="/images/logo.png"
              alt="Lilla Kiosken"
              className="logo-image"
            />
          </Link>
        </div>
        <nav className="nav">
          <ul className="nav-list">
            <li>
              <NavLink to="/" className="nav-link">
                Hem
              </NavLink>
            </li>
            <li>
              <NavLink to="/recensioner" className="nav-link">
                Recensioner
              </NavLink>
            </li>
            <li>
              <NavLink to="/utmaningar" className="nav-link">
                Utmaningar
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
